<template>
  <div class="header">
     
          <span class="title-font">{{title}}</span>
  </div>
</template>
<script>
 export default {
     data(){
      return{
         title:''
      }
     },
     mounted(){
     },
     methods:{
      yiru(){
         document.querySelector('.text-back').style.top= '0px'
         document.querySelector('.text-back').style.transition= 'all 0.3s linear'
      },
      yichu(){
        document.querySelector('.text-back').style.top= '-40px'
      },
      back(){
        this.$router.go(-1)
      }
     }
 }
</script>
<style lang="less" scoped>
 .header{
  width: 99%;
  background: white;
  margin: auto;
  border-radius: 5px;
 }
 .title-font{
  font-size: 20px;
  font-weight: 600;
  color: #666;
  position: relative;
  left: 40px;
  line-height: 70px;
 }
 .title-font::before{
  content: "";
  width: 5px;
  height: 30px;
  background: rgb(43, 43, 255);
  position: absolute;
  left: -15px;
  bottom: -3px;
 }
 //返回图标
 .icon-back ,.text-back,.back{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  position: absolute;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
 }

.text-back{
  position: absolute;
  top: -40px;
  font-size: 14px;
  background: #e6f7ff;
  color: #1890ff;
}
.back{
  overflow:hidden;
  position: absolute;
  left: 20px;
  top: 14px;
}



</style>