<template>
<div class="nav-box">
<el-menu
    :collapse="$store.state.isNavTelescoping"
     router:true
     :unique-opened='true'
      class="el-menu-vertical-demo"
      background-color="rgba(0, 21, 41, 1)"
      text-color="white"
      active-text-color="white"
      :default-active="$store.state.Navhighlighted.path "
      >
    <div class="title" :class="$store.state.isNavTelescoping ? 'titletwo' : 'title'"><span v-if="!$store.state.isNavTelescoping">CAMEL团购系统</span>  </div>
    <div v-for="(item,key ) in list" :key="key">
        <el-menu-item :index="item.id.toString()"  v-if="!item.children" @click="click_togo(item)">
            <i :class="item.web_icon"></i>
            <span slot="title">{{item.title}}</span>
        </el-menu-item>
      <el-submenu :index="item.id.toString()" v-else>
        <template slot="title" >
          <i :class="item.web_icon"></i>
          <span> {{item.title}}</span>
        </template>
        <el-menu-item-group v-for="(child,key ) in item.children" :key="key">
            <el-menu-item @click="click_togo(child)" :index="child.path"> <i :class="child.web_icon"></i>{{child.title}}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </div>
    </el-menu>
</div>
</template>

<script>
  export default {
    data(){
        return{
        list:[],
        pathlist:[],
        //按钮
        threebut:[],
        //点击菜单的时候 获取到的 页面按钮
        butpage:null,
    }
    },
    mounted(){
        sessionStorage.getItem('caidanxinxi') ? this.list = JSON.parse(sessionStorage.getItem('caidanxinxi')) : this.bus.$on('getNavList',(data)=>{ this.list = data })
    },
   
    methods: {
    click_togo(child){
        this.$router.push({
            path:child.web_path
        })
    },
    }
  }
</script>

<style lang="less" scoped>
.nav-box{
    height: 100vh;
}
/deep/.el-menu-vertical-demo{
    height: 100vh;
    overflow: hidden;
}
/deep/.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
  }
   /deep/.el-menu--collapse  .el-submenu__title span{
    display: none;
  }
  /*隐藏 > */
  /deep/.el-menu--collapse  .el-submenu__title .el-submenu__icon-arrow{
    display: none;
  }
  /deep/.el-menu {
    height: 100%;
  }
/deep/.el-menu {
    height: 100%;
}

/deep/.el-dropdown-menu__item, .el-menu-item{
    font-size: 1rem;
}

/deep/.el-submenu__title{
    width: 216px;
    font-size: 1rem;
}
/deep/.el-submenu .el-menu-item{
    width: 100%;
    font-size: 1rem;
    text-align:left;
}

/deep/ .el-menu-item-group__title{
    padding:  0px;
}

.title{
    height: 70px;
    font-weight: 650;
    font-style: normal;
    font-size: 1rem;
    color: #CCCCCC;
    text-align: center;
    line-height: 70px;
}
 .titletwo{
    background-image: url('@/assets/images/logo.png') ;
    background-repeat: no-repeat;
    background-size: 64px;
}

/deep/.el-menu-item:focus, .el-menu-item:active{
    background: red;
}

/deep/.el-submenu .el-menu-item:active{
    color:'red' !important;
    background-color: #1890ff !important;
}
/deep/.el-submenu .el-menu-item:hover{
    color:'red' !important;
    background-color: #1890ff !important;
}
//点击切换背景颜色
/deep/.el-menu-item.is-active {
   background-color: #1890ff !important;
}

</style>
