<template>
<div class="tab-box">
    <div>
      <el-tabs class="tab-list" v-model="$store.state.nowRouterPath" type="card"  @tab-click="hanelClickTab" closable @tab-remove="removeTab">
        <el-tab-pane
          v-for="(item, index) in $store.state.tabNavList"
          :key="item.fullPath"
          :label="item.query.edit == 0 ? '新建'+item.meta.title : item.query.edit == 1 ? item.meta.title+'详情' : item.meta.title"
          :name="item.fullPath"
        >
        </el-tab-pane>
      </el-tabs>
    </div>

  <div class="tab-more">
    <el-dropdown trigger="click" @command="changeValue1"> 
      <span class="el-dropdown-link">
        操作<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" >
        <el-dropdown-item command="删除其他" >删除其他</el-dropdown-item>
        <el-dropdown-item command="删除所有" >删除所有</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</div>
</template>

<script>
 export default {
    data() {
      return {
        path:{},
        tabIndex: 0,
        inde:0,
      }
    },
    mounted(){
    },
    methods: {
      changeValue1(val){
       this.$store.commit('CleatStroenowRouterPath',val)
      },
     
      removeTab(targetName) {
        this.$store.commit('removeTab',targetName)
      },
      hanelClickTab(tab, event){
        let data = this.$store.state.tabNavList
        data.map(item=>{
          if(item.fullPath === tab._props.name){
            this.$router.push({
              path :item.path,
              query:item.query
            })
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
 .tab-box{
  display: flex;
  justify-content: space-between;
 }
 .tab-list{
  width: 95%;
  height: 40px;
  position: absolute;
  align-content: flex-start;
  overflow: hidden;
  background: white;
  border:  1px solid  #ccc;
 }
 .tab-more{
  width: 5%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-top:1px solid #ccc ;
  border-bottom:1px solid #ccc ;
 }

 /deep/  .el-tabs--card>.el-tabs__header .el-tabs__nav{
   border: none;
   border-right: 1px solid #ccc;
   border-radius:0px 0px 0px 0px;
 }
/deep/ .el-tabs__item.is-active{
  color: #1890ff;
  background:#e6f7ff;
}
/deep/ [data-v-4b3105f8] .el-tabs__item.is-active::after{
    content: "";
    width: 100%;
    height: 2px;
    background: #1890ff;
    position: absolute;
    bottom: 0;
    left: 0;
}


/deep/.el-tabs__item .el-icon-close:hover{
  background: red;
}
/deep/.el-tabs__item {
  height: 41px;
}
</style>