<template>
<el-breadcrumb separator="/" class="breadcrumb">
  
    <div class="breadcrumb-box" >
         <el-breadcrumb-item >{{this.$store.state.Navhighlighted.Plabel}}</el-breadcrumb-item>
         <el-breadcrumb-item >{{this.$store.state.Navhighlighted.label}}</el-breadcrumb-item>
    </div>
    <div class="user"><my-user></my-user></div>
</el-breadcrumb>
</template>
<script>
export default {
    data(){
        return{
            list:{},
            navList:[],
        }
    },
    mounted(){
    },
    methods:{
        
        
        }
    }
</script>
<style lang="less">
.breadcrumb{
    margin-left: 1px;
    height: 50px;
    background: white;
}
.breadcrumb-box{
    height: 50px;
    line-height: 50px;
    display: flex;
    flex-direction: row;
}
.user{
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
}
.box_bgd{
    width: 5vw;
    height: 50px;
    background: red;
}

.el-icon-s-unfold ,.el-icon-s-fold {
    font-size: 25px;
}
</style>