<template>
<div>
<div class="user">
    <div class="user-content">
        <div class="user-img"> <img class="user-img" src="@/assets/images/logo.png" alt="" srcset="" ></div>
        <div class="text"> <span style="font-size:12px;">{{suername}}</span>   <i :class=" [ ishover  ? 'el-icon-arrow-up' : 'el-icon-arrow-down' ]"></i></div>
    </div>
</div>
<div class="user-u">
    <div class="user-u_content">
        <div class="ChangePassword" @click="openResetPassword"> <i class="el-icon-key" ></i>   修改密码</div>
        <div class="Logout" @click="out" ><i class="el-icon-lock"></i>注销登录</div>
    </div>
</div>
<!-- 重置密码 -->
      <el-dialog
        :show-close="true"
        lock-scroll
        :visible.sync="resetpassword"
        :modal-append-to-body="false"
        title="重置密码"
        width="30%"
      >
        <el-form
          :model="resetpasswordform"
          label-width="90px"
          style="margin: 30px"
        >
          <el-form-item label="用户代码:">
            <el-input
              clearable
              v-model="resetpasswordform.username"
              :disabled="true"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="输入新密码:">
            <!-- @keyup.native="addruleForm.customer_phone=addruleForm.customer_phone.replace(/[\D]/g,'')" :键盘按下 查看是否是除数字外的字符 替换为空 -->
            <el-input
              clearable
              v-model.trim="resetpasswordform.password"
              @keyup.native="
                resetpasswordform.password = resetpasswordform.password.replace(
                  /\s+/g,
                  ''
                )
              "
            ></el-input>
            
          </el-form-item>
          <div class="parssword_prompt">密码必须包含至少一个字母、一个数字，密码长度必须在8到20个字符之间, 不包含特殊字符!</div>
        </el-form>
        
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetpassword = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            size="small"
            style="color:white"
            @click="resetPasSub(resetpasswordform)"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- 重置密码 end -->

</div>
</template>

<script>

//导入state数据
import {mapState} from 'vuex'
//导入mapMutations中的注销登录方法
import { mapMutations } from 'vuex'
export default {
     data(){
        return{
            circleUrl:'',
            ishover:false,
            suername:'',
            //修改密码显示隐藏
            resetpassword:false,
            //修改密码
            resetpasswordform:{
                id:0,
                username:'',
                password:''
            },
            //判断是否是从首页中点击的修改密码
            indexgo:''
        }
     },
    created(){

        // /**刷新页面直接从stata中获取数据*/
        // this.$store.commit('get_user')

    },
    mounted(){
        this.suername = localStorage.getItem('name')
    },
    computed:{
        // ...mapState({
        //     loginStatus: 'loginStatus',
        //     userInfo:'userInfo'
        // })
    },
     methods:{
        //引入store中mutations方法
        ...mapMutations(['log_out']),

       //退出登录
        out(){
            this.$axios.post('/api/logout').then((res=>{
                    this.$message({
                        message:'已退出登录',
                        type:'success',
                    })
            }))
            this.$store.commit('clreatStroeData',1)
            this.$router.push('/')
            localStorage.setItem('isLoginOut', true);//登出标记
        },
        //重置密码
            openResetPassword() {
            this.resetpasswordform.id = localStorage.getItem('user_id')
            this.resetpasswordform.username = localStorage.getItem('username')
            this.resetpassword = true;
            },
            //重置密码确认按钮
            resetPasSub(val) {
            this.$axios
                .post("/api/system/user/reset_password", {
                id: val.id,
                username: val.username,
                password: val.password,
                })
                .then((res) => {
                console.log("res", res);
                let data = res.data;
                if (data.status_code === 200) {
                    this.$message({
                    message: data.message,
                    type: "success",
                    });
                    this.resetpassword = false;
                    this.resetpasswordform={
                        id:0,
                        username:'',
                        password:''
                    }
                } 
                });
            },
    }
}

</script>

<style lang="less" scoped>
*{
    color: #999999;
}
.user{
    width: 120px;
    height: 50px;
}

.user-content{
        height: 100%;
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

}
.user-img{
    width: 45px;
    position: relative;
    left: 0px;
}

.user:hover{
    background: #cccc;
}
.user-u :hover .user{
    background: #cccc;
}
.user:hover .user-u{
    transition: all;
    transition-duration:1s;
    display:inline-block;

}

.user-u{
    width: 120px;
    height: 50px;
    background: white;
    position: absolute;
    top: 50px;
    right: 0;
    display: flex;
    align-items: center;
    box-shadow:0.3px 0.3px 0.3px #ccc,
    0.3px 0.3px 0.3px #ccc,
    0.3px 0.3px 0.3px #ccc,
    0.3px 0.3px 0.3px #ccc ;
    display:none;
}
.user-u_content{
    width: 100%;
    height: 80%;
    font-size: 12px;
    margin-top: 5%;
}
.user-u_content div {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ChangePassword i,.Logout i{
    margin-right: 5px;
    margin-top: 2px;
}
.ChangePassword:hover , .Logout:hover{
    background: #cccc;
    cursor: pointer;
}
.text{
    width: 60px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.parssword_prompt{
  font-size: 11px;
  padding-left: 92px;
  margin-top: -16px;
  color: red;
}
</style>
