<template>
<div >
  <el-button v-if="type === 'create'" icon="el-icon-plus" type="primary"  :size="butsize">创建{{but_title}}</el-button>
  <el-button v-else-if="type === 'add'"  icon="el-icon-plus" type="primary"  :size="butsize">新增{{but_title}}</el-button>
  <el-button v-else-if="type === 'import'" icon="el-icon-bottom"   :size="butsize" >商品导入</el-button>
  <el-button v-else-if="type === 'min_import'" icon="el-icon-bottom"   :size="butsize" >最低价导入</el-button>
  <el-button v-else-if="type === 'importSalesOrder'" icon="el-icon-bottom"   :size="butsize" >导入{{but_title}}</el-button>
  <el-button v-else-if="type === 'export'" icon="el-icon-top" :size="butsize">导出</el-button>
  <el-button v-else-if="type === 'confirm'" icon="el-icon-check"  type="success"  :size="butsize"  >批量确认</el-button>
  <el-button v-else-if="type === 'accounting'" icon="el-icon-edit-outline" type="warning" :size="butsize" >批量记账</el-button>
  <!-- 子页面的批量 -->
  <el-button v-else-if="type === 'batch'" icon="el-icon-setting" type="info" :size="butsize" >批量{{but_title}}</el-button>
</div>
</template>
<script>
 export default {
  props:{
    type:{
      type:String
    },
    text:{
      type:String
    },
    but_title:{
      type:String
    },
    // dropdownList:{
    //   type : Array
    // },
    // butpage:{
    //   type:Array
    // }
    
  },
  data(){
    return{
      //按钮大小 
      butsize:'small',
    }
  },
  methods:{
   
  },
 }
</script>
<style scoped>

</style>

