import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //6.26 登录页面的时候 就会把 用户的所有按钮传递过来
    butlist: [],
    //6.15保存 nva组件点击获取到的页面按钮
    pagebut: [],
    CITY:[],//城市信息选择列表
    User_type : null,
    isNavTelescoping:false,//是否点击了Nav缩放
    tabNavList:[],//标签页的数据
    nowRouterPath:'',//当前路由
    Navhighlighted:{},
    NavClickPath:[],//存放导航的path，当页面存在创建，详情时遍历该数组找到对应的列表页

  },
  //修饰符属性 加入在一个变量中添加一个字符串
  getters: {

  },
  //同步
  mutations: {
    getpagebutlist(state, path) {
      if(sessionStorage.getItem('caidanxinxi')) {
        state.pagebut = []
      //判断butlist 是否是空,如果为空就去sessionStorage中拿取菜单信息
      state.butlist = JSON.parse(sessionStorage.getItem('caidanxinxi'))
      // //6.26: 遍历菜单 , 获取到路由守卫传递过来的 to.path将前往的页面,与菜单数据对比 
      for (let i in state.butlist) {
        //循环 二级菜单 菜单按钮都在二级菜单中的child中
        for (let k in state.butlist[i].children) {
          //给二级菜单添加 pathname字段 用于和路由传过来的 路由name对比 找到对应的 菜单
          state.butlist[i].children[k].pathname = state.butlist[i].children[k].path.substring(1, state.butlist[i].children[k].path.length)
          //    找到对应的菜单
          if(path !== null){
            if (path.indexOf(state.butlist[i].children[k].pathname) !== -1) {
              //循环菜单的children 字段 遍历出所有的按钮
              // console.log('state.butlist[i].child[k]', state.butlist[i].child[k]);
              //children 属性 存放着 页面显示的按钮权限
              for (let s in state.butlist[i].children[k].children) {
                //     //将这个菜单的按钮添加到state.pagelist中 每个页面都有获取这个数据的操作
                state.pagebut.push(state.butlist[i].children[k].children[s].slug)
                //判断按钮的对象中 是否存在 按钮权限 :比如 更多操作 和 详情页的按钮权限 等
                if (state.butlist[i].children[k].children[s].children.length !== 0) {
                  for (let t in state.butlist[i].children[k].children[s].children) {
                    state.pagebut.push(state.butlist[i].children[k].children[s].children[t].slug)
                  }
                }
              }
            }
         }
        }
      }
      sessionStorage.setItem('pagebut', state.pagebut)
      return 1
    }
    },
    getRouterList_Path(state,data){
      let newData
      let ispush = false
      if(state.tabNavList.length == 0){
        sessionStorage.getItem('TabNavList') ? state.tabNavList = JSON.parse(sessionStorage.getItem('TabNavList'))  : ''
      }
        //1.将路由参数赋值 避免直接赋值 修改提示只读
        let {fullPath,hash,meta,name,params,query,path} = data
        //2.定义变量总和起来
         newData ={
          fullPath,hash,meta,name,params,query,path
        }
        //3.拷贝
        state.nowRouterPath = JSON.parse(JSON.stringify(newData.fullPath))
        //6.判断保存route的信息，tabNavList：tab标签页的的数据
        if(state.tabNavList.length != 0 ){
          //7.循环数据
          for (const index in state.tabNavList) {
            //8.判断本次传递过来的路由参数，是否和tab标签中的path是否不相同
            if(state.tabNavList[index].path != newData.path){
              //9.如果不相同就可以直接存入 ,标识该页面信息没有在tab标签页中
              ispush = true
            }else{
              //10.如果相同的话，相比本次路由信息的edit是否一直，如 创建页面 和 详情页面是 同一个vue文件，edit：0标识的创建,eidt:1 是便是详情
                if(state.tabNavList[index].query.edit != newData.query.edit){
                  //11.便是可以存入
                  ispush = true
                }else{
                  //12.如果edit都相同的话
                  //11.判断此路由传递的参数的对象长度不等于0
                  if(Object.keys(newData.query).length != 0){
                    //12.直接想当前的路由信息，替换掉tabNavList中的数据
                    state.tabNavList[index] = newData
                  }
                  ispush = false
                  break;
                }
            }
          }
        }else{
          //tabNavList没有数据 直接添加进去
          state.tabNavList.push(JSON.parse(JSON.stringify(newData)))   
      }
      ispush ? state.tabNavList.push(JSON.parse(JSON.stringify(newData)))    : ''
      sessionStorage.setItem('TabNavList', JSON.stringify(state.tabNavList))
      state.NavClickPath.length == 0 ? state.NavClickPath = JSON.parse(sessionStorage.getItem('NavClickPath')) : ''
      if(state.NavClickPath.length != 0 && data.query){
        state.NavClickPath.map(item=>{
          if(data.fullPath.indexOf(item.path) !== -1){
            state.Navhighlighted = item
          }
        })
      }else{
        state.Navhighlighted = data
      } 
      //判断是否本地中没有存在 NavClickPath 
      sessionStorage.getItem('NavClickPath') !== null ? '' : sessionStorage.setItem('NavClickPath',JSON.stringify(state.NavClickPath))
    },
    //点击删除tab数据
    removeTab(state,routerPath){
      state.tabNavList.map((item,index)=>{
        if(item.fullPath == routerPath){
          state.tabNavList.splice(index,1)
          router.push(state.tabNavList[index] == undefined  ?  state.tabNavList[state.tabNavList.length -1]  : state.tabNavList[index])
        }
      })

    },
    //获取菜单中的所有路径，然后匹配当前路由，如果当前路由中包含菜单中的路由，就是存在关联关系，就让菜单中的path高亮
    getNavClickPath(state,path){
      state.NavClickPath = path
    },
    updataUserType(state,data){
        state.User_type = data
    },
    UpdataNavTelescoping(state){
      state.isNavTelescoping  = ! state.isNavTelescoping
    },
    //清除Store数据
    clreatStroeData(state,type){
      localStorage.clear()
      sessionStorage.clear()
      if(type == 1){
        state.Navhighlighted = []
        state.tabNavList = []
        state.NavClickPath = []
      }
    },
    //删除全部 和 删除其他功能
    CleatStroenowRouterPath(state,type){
      if(type == '删除其他'){
       let path = router.history.current.path
       state.tabNavList = state.tabNavList.filter(item => item.path.includes(path))
      }else if(type == '删除所有'){
        sessionStorage.removeItem('TabNavList')
        state.tabNavList = []
        state.NavClickPath = []
        state.Navhighlighted = {}
        router.push('/index')
      }
    },
  },
  //异步方法
  actions: {

  },
  modules: {

  }
})