  const success = 'success'
  const info = 'info'
  const warning = 'warning'
  const danger = 'danger'

  const blue_back = 'blue' 
  const brown_back = '#ecc663' 
  export default  {
    success,
    info,
    warning,
    danger,
    blue_back,
    brown_back
  }
