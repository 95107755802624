<template>
  <div>
    <!-- 成功 完成 是 -->
    <el-tag v-if="type === 'success'" type="success">{{text}}</el-tag> 
    <!-- 已售罄 否 待审单确认  -->
    <el-tag v-else-if="type === 'info'" type="info">{{text}}</el-tag>
    <!-- 待记账 待财务确认 -->
    <el-tag v-else-if="type === 'warning'" type="warning">{{text}}</el-tag>
    <!-- 禁用 已终止 已下架 已关闭  -->
    <el-tag v-else-if="type === 'danger'" type="danger">{{text}}</el-tag>
    <el-tag v-else >{{text}}</el-tag>
  </div>
</template>

<script>
 export default {
  props:{
    type:{
       type:String
     },
     text:{
       type:String
     },
     color:{
      type:String
     }
  }
 }
</script>
