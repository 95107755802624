import Vue from 'vue'
import { Cell, CellGroup, Icon, NavBar, Step, Steps, Popup, Button, RadioGroup, Radio,Field   } from "vant";
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Popup);
Vue.use(Button);
Vue.use(RadioGroup);
Vue.use(Radio); 
Vue.use(Field); 